<template>
  <div>
    <div class="company-name">
      <div class="company-logo">
        <img
          style="width: 60px; height: 60px"
          src="@/assets/image/com-message/logo.png"
        />
      </div>
      <div>
        <span>公司名称：{{ companyDataInfo.company_name }}</span>
        <br />
        <p class="certification">已认证</p>
      </div>
      <div>
        <span>企业号</span> <br />
        <span>{{ companyDataInfo.open }}</span>
      </div>
      <div class="credit">
        <ul >
          <li>{{ companyDataInfo.credit }}</li>
          <li>信用</li>
        </ul>

        <ul >
          <li>{{ companyDataInfo.fans }}</li>
          <li>粉丝</li>
        </ul>

        <ul >
          <li>{{ companyDataInfo.popularity }}</li>
          <li>人气</li>
        </ul>

        <ul >
          <li>{{ companyDataInfo.follow_count }}</li>
          <li>关注</li>
        </ul>
      </div>
    </div>
    <div class="induction">
      <div class="company-induce">
        <div class="induce-title">
          <div class="ind-title">
            <i class="titleline"></i>
            <span style="margin-left: 20px">企业介绍</span>
          </div>
        </div>
        <div class="study"><span>{{ companyDataInfo.description }}</span></div>

        <div class="study">
          
          <img v-for="(item,index) in companyDataInfo.vivid_image" :key="index" :src="item" />
        </div>
      </div>
    </div>
    <div class="induc-server">
      <div class="company-induce">
        <div class="induce-title">
          <div class="ind-title">
            <i class="titleline"></i>
            <span style="margin-left: 20px">产品服务展示</span>
          </div>
        </div>
        <div class="study">
          <img v-for="(item,index) in companyDataInfo.service_image" :key="index" :src="item" />
         
        </div>
      </div>
    </div>
    <div class="project">
      <div>
        <ul class="project-card">
          <li
            class="litte-card"
            :class="option == 0 ? 'card' : ''"
            @click="card"
          >
            岗位（60）
          </li>
          <li
            class="litte-card"
            :class="option == 1 ? 'card' : ''"
            @click="card1"
          >
            项目（{{ companyDataInfo.project.length }}）
          </li>
          <li
            class="litte-card"
            :class="option == 2 ? 'card' : ''"
            @click="card2"
          >
            主管（{{ companyDataInfo.userExamines.length }}）
          </li>
        </ul>
      </div>
      <hr />
      <div></div>

      <div v-show="option == 0" >
        <div class="proo">
          <div class="pro"  v-for="(item,index) in companyDataInfo.job" :key="index">
            <img :src="item.logo" alt="" /> <span>{{ item.title }}</span>
          </div>
        </div>
      </div>

      <div v-show="option == 1" >
        <div class="proo">
          <div class="pro"  v-for="(item,index) in companyDataInfo.project" :key="index">
            <img :src="item.image" alt="" /> <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div v-show="option == 2">
        <div class="proo"  >
          <div class="pro" v-for="(item,index) in companyDataInfo.userExamines" :key="index">
            <img :src="item.avatar" /> <span>{{ item.nickname }}</span></div>
        </div>

       
      </div>
    </div>
    <div class="commit">
      <div class="company-induce">
        <div class="induce-title">
          <div class="ind-title">
            <i class="titleline"></i>
            <span style="margin-left: 20px">企业评价</span>
          </div>
        </div>
        <div class="study1">
          <div>
            <span style="text-align:center">综合评分</span>
            <div>
              <el-rate v-model="companyDataInfo.com_rank"></el-rate>
            </div>
          </div>
          <div style="height: 20px"></div>

          <br />
          <div class="bu" style=""><span>不错</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { companyInfo} from "@/api/manage/index";

export default {
  data() {
    return {
      option: 0,
     
      companyDataInfo:{
          company_app_id: null,
          company_id: null,
          company_name: null,//企业名称

          fans: 0,//粉丝
          credit: 0,//信用
          state: 1,
          popularity: 0,//人气
          follow_count: 0,//关注

          evaluate: "",
          vivid_image: null,
          service_image: null,
          source: "",
          password: "",
          contact: "",


          mobile: "",
          email: "",
          token: "",
          sort: "",
          is_ask: "",
          is_top: "",
          recommender: "",
          examine_at: null,
          group_id: null,
          pay_password: null,
          pay_at: null,
          pay_user_commit_id: null,
          company_impress: null,
          description: "",//企业描述
          log_img: "",//企业logo
          img: "",
          abbreviation: "",
          ex_state: "",
          type: "",
          address: "",
          project: [],//企业下项目
          userExamines: [],//企业下主管
          state_type: "",
          disabled: false,
          is_real: "",//1实名，2未实名
          is_follow: "",
          job: [],//企业下职位
          assess: []//评价信息//          content:"",//评价内容// created_at:"",//评价时间
         

      }
    };
  },
  mounted(){
    this.initInfo();
  },
  methods: {

    initInfo(){
      var that = this;
      companyInfo({}).then((response) => {
        console.log(response);
        if(response.code==200){
          that.companyDataInfo=response.data
        }else{
          
          that.$message.error(response.msg?response.msg:"请求错误");
        }
      });
    },

    card() {
      this.option = 0;
      // alert(this.option);
    },
    card1() {
      this.option = 1;
      // alert(this.option);
    },
    card2() {
      this.option = 2;
      // alert(this.option);
    },
  },
};
</script>

<style lang="scss" scoped>
.layui-icon layui-icon-rate-solid {
  font-size: 30px;
  color: #ffb800;
  margin-right: 5px;
  -webkit-transition: all 0.3s;
}
.bu {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #808080;
  width: 40px;
  height: 30px;
  border-radius: 5px;
}
.proo {
  display: flex;
  flex-flow: wrap;
}
.pro {
  margin: 10px;
  display: flex;
  width: 400px;
}

.credit{
 display: flex;
  ul {
    margin-left: 10px;
  }
  li{
    text-align: center;

 }
}
.card {
  border-bottom: 3px solid #4c71db;
}
.project-card {
  display: flex;
  margin: 20px 20px 20px 20px;
}
.litte-card {
  margin-right: 20px;
}
.company-name {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  height: 100px;
}
.company-logo {
  margin: 20px;
  display: flex;
}
.certification {
  width: 50px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 6px;
  margin-left: 10px;
  font-size: 12px;
  background-color: #4c71db;
  color: white;
}
.company-induce {
  margin: 20px 0px 20px 0px;
  display: flex;
  background-color: white;
  border-radius: 5px;
  height: 222px;
  flex-direction: column;
  justify-content: space-around;
}
.induce-title {
  height: 24px;
  margin: 20px;
  display: flex;
  position: relative;
}
.ind-title {
  width: 150px;
  height: 24px;
  display: flex;
  align-items: center;
}
.titleline {
  position: absolute;
  /* top: 26px; */
  /* left: 26px; */
  width: 6px;
  height: 30px;
  background: #4c71db;
}
.study {
  margin: 10px 0px 10px 20px;
}
.study1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img {
  width: 90px;
  height: 68px;
  margin-right: 30px;
  border-radius: 5px;
}
.inducserve {
  display: flex;
}
.project {
  margin: 20px 20px 20px 0px;
  display: flex;
  background-color: white;
  border-radius: 5px;
  flex-direction: column;
}
</style>
